var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.choicees.length && _vm.enabled ? _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "필수선택"
    }
  }), _c('v-select', _vm._b({
    attrs: {
      "return-object": "",
      "placeholder": "필수상품선택",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "disable-lookup": "",
      "items": _vm.choicees
    },
    on: {
      "change": _vm.emit
    }
  }, 'v-select', {
    itemText: _vm.itemText,
    itemDisabled: _vm.itemDisabled
  }, false)), _c('div', {
    staticClass: "txt txt--xs mt-12px"
  }, [_vm._v(" *콜러구매시 설치부속(택일)은 무상제공합니다. (그 외 브랜드는 유상) "), _c('br'), _vm._v(" *수동폽업은 포함되어 있으나 자동폽업은 별도 구매바랍니다. ")])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
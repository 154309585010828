<template>
    <v-card color="grey lighten-5" class="txt txt--xs">
        <div class="pa-10px pa-md-16px">
            <div class="mb-8px mb-md-12px">{{ $decode__productOptionName(cart.name) }} <span v-if="cart.stock == 0">(품절)</span></div>
            <v-row no-gutters align="center">
                <v-col cols="auto">
                    <!-- 옵션 수량 -->
                    <div class="spinner">
                        <v-btn icon tile class="v-size--xx-small transparent" @click="$emit('decAmount', cart)"><v-icon color="grey">mdi-minus</v-icon></v-btn>
                        <input v-model="cart.amount" class="spinner__num" type="number" @change="$emit('changeAmount', cart)" :disabled="!cart.stock" />
                        <v-btn icon tile class="v-size--xx-small transparent" @click="$emit('incAmount', cart)"><v-icon color="grey">mdi-plus</v-icon></v-btn>
                    </div>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <span class="d-flex align-center">
                        <strong class="font-size-16 font-size-lg-20">{{ (salePrice * cart.amount).format() }}</strong>
                        <span class="pl-2px">원</span>
                    </span>
                </v-col>
                <v-col cols="auto" v-if="cart._option || cart._supply || cart._related || cart._choicee || clearable">
                    <v-btn small icon class="transparent mr-n4px mr-md-n10px" @click="$emit('remove', index)">
                        <v-icon class="font-size-inherit grey--text text--lighten-1">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <!-- <div class="mt-4px mt-md-8px">
                <v-text-field v-model="cart.requestMessage" dense outlined hide-details placeholder="상품요청사항" @input="emit" />
            </div> -->
        </div>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        cart: { type: Object },
        index: { type: Number, default: 0 },
    },
    computed: {
        ...mapGetters(["getDiscountPrice", "getDiscountRate", "getSalePrice"]),
        discountPrice() {
            return this.getDiscountPrice(this.cart);
        },
        discountRate() {
            return this.getDiscountRate(this.cart);
        },
        salePrice() {
            return this.getSalePrice(this.cart);
        },
    },
};
</script>

<style>
</style>
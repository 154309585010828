<template>
    <page-section class="page-section--xs pb-0" v-if="choicees.length && enabled">
        <tit-wrap-x-small title="필수선택" />
        <v-select return-object placeholder="필수상품선택" dense outlined hide-details disable-lookup :items="choicees" v-bind="{ itemText, itemDisabled }" @change="emit" />
        <div class="txt txt--xs mt-12px">
            *콜러구매시 설치부속(택일)은 무상제공합니다. (그 외 브랜드는 유상) <br />
            *수동폽업은 포함되어 있으나 자동폽업은 별도 구매바랍니다.
        </div>
    </page-section>
</template>

<script>
import { initProduct } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        PageSection,
        TitWrapXSmall,
    },
    props: {
        value: { type: Array, default: () => [] }, // carts
        product: { type: Object, default: initProduct },
    },
    computed: {
        choicees() {
            return (this.product?.choicees || []).filter(({ enabled }) => enabled);
        },
        enabled() {
            return this.product?.choiceesConf?.enabled || false;
        },
    },
    methods: {
        emit(item) {
            if (item && !this.value.find((cart) => cart._choicee == item._id)) {
                const cart = {
                    _product: this.product._id,
                    _choicee: item._id,
                    product: this.product,
                    name: `${this.product.name} / ${item.name}`,
                    price: this.product.price + item.price,
                    salePrice: this.product.salePrice + item.salePrice,
                    discountPrice: this.product.discountPrice + item.discountPrice,
                    stock: [this.product.stok, item.stock].sort((a, b) => a - b).shift(),
                    amount: 1,
                };
                this.$emit("input", [...this.value, cart]);
            }
        },
        itemText({ name, salePrice, stock }) {
            let text = this.$decode__productOptionName(name);
            if (stock) {
                if (salePrice) {
                    if (0 <= salePrice) text += ` (+${salePrice.format()}원)`;
                    else text += ` (${salePrice.format()}원)`;
                }
            } else text += " (품절)";
            return text;
        },
        itemDisabled({ stock }) {
            return stock == 0;
        },
    },
};
</script>

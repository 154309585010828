<template>
    <page-section class="page-section--xs pb-0">
        <tit-wrap-x-small title="배송선택" />
        <v-select v-model="shippingOption" placeholder="선택" return-object item-value="_id" v-bind="{ ...attrs_input, items }" @input="emit" />
        <div class="txt txt--xs mt-12px">
            *배송,설치서비스를 신청하면 클릭한번으로 배송부터 설치까지 한번에 해결됩니다. (서울/경기 지역 외 상담요망)<br/>
            *상품의 부피와 무게에 따라 배송요금이 부과되며 해당 택배사의 요금기준을 따릅니다.
        </div>

        <!-- <tit-wrap-x-small class="mt-12px">
            <template #titleContents>
                <div class="d-flex align-center">
                    <icon-truck-2 class="mr-4px" />
                    <span>지역배송 & 방문설치 서비스</span>
                </div>
            </template>
        </tit-wrap-x-small>
        <v-card outlined rounded="md" color="grey darken-4">
            <div class="pa-10px pa-md-16px white">
                <p class="txt txt--xs txt--dark">지역배송 & 방문설치 서비스는 담당기사님께서 직접 방문드려 설치해주는 서비스입니다. <br class="d-none d-md-block" />기본 설치에 필요한 부속품 가격이 함께 측정된 가격이며, 필요 시 추가비용이 발생할 수 있습니다.</p>
            </div>
        </v-card> -->
    </page-section>
</template>

<script>
// import IconTruck2 from "@/components/client/icons/icon-truck2.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

import { attrs_input, initProduct, initShopShippingOption, SHIPPING_OPTION_CHARGE_TYPES } from "@/assets/variables";
import { mapGetters, mapState } from "vuex";

export default {
    components: {
        // IconTruck2,
        PageSection,
        TitWrapXSmall,
    },
    props: {
        value: { type: Object, default: initShopShippingOption },
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        shippingOption: initShopShippingOption(),

        attrs_input,
    }),
    computed: {
        ...mapState(["shipping__everyAsset"]),
        ...mapGetters(["shippingCodes"]),
        items() {
            return ((this.product?.shipping || this.shipping__everyAsset)?.options || []).map((item) => {
                let text = this.shippingCodes.find(({ value }) => value == item.code)?.text || "-";

                let chargeAmount = 0;

                switch (item.charge.type) {
                    case SHIPPING_OPTION_CHARGE_TYPES.BY_FIXED_AMOUNT.value: {
                        chargeAmount = item?.charge?.fixedAmount;
                        break;
                    }
                    case SHIPPING_OPTION_CHARGE_TYPES.BY_PRICES_RANGE.value: {
                        chargeAmount = [...(item.charge.pricesRange || [])].sort((a, b) => a.amount - b.amount)[0]?.amount;
                        break;
                    }
                    case SHIPPING_OPTION_CHARGE_TYPES.BY_TRAVEL_RANGE.value: {
                        chargeAmount = [...(item.charge.travelRange || [])].sort((a, b) => a.amount - b.amount)[0]?.amount;
                        break;
                    }
                }

                if (item.service.isActive && item.service.charge.amount) chargeAmount += item.service.charge.amount;

                if (chargeAmount) text += ` (+${chargeAmount?.format?.()}원)`;

                return {
                    ...item,
                    text,
                };
            });
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.shippingOption = initShopShippingOption(this.value);
        },
        emit() {
            this.$emit("input", this.shippingOption);
        },
    },
};
</script>

<style>
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items.length ? _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "옵션선택"
    }
  }), _vm._l(_vm.items, function (_ref, index) {
    var name = _ref.name,
      values = _ref.values;
    return [_c('v-select', {
      key: name,
      class: {
        'mt-1 mt-md-2': index != 0
      },
      attrs: {
        "placeholder": name,
        "items": values,
        "item-text": "name",
        "item-value": "name",
        "dense": "",
        "outlined": "",
        "hide-details": ""
      },
      on: {
        "change": function ($event) {
          return _vm.emit(index);
        }
      },
      model: {
        value: _vm.selected[name],
        callback: function ($$v) {
          _vm.$set(_vm.selected, name, $$v);
        },
        expression: "selected[name]"
      }
    })];
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }